var render = function render(){var _vm=this,_c=_vm._self._c;return _c('geko-base-crud',{key:'program-soc-detail' + _vm.componentKey,attrs:{"config":_vm.config,"hideDelete":!['13'].includes(_vm.$store.state.User.role),"hideUpdate":true},on:{"onExportExcel":function($event){return _vm.onExportExcel($event)}},scopedSlots:_vm._u([{key:"list-form_no",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column min-w-150px"},[_c('p',{staticClass:"mb-0 text-link font-weight-500"},[_vm._v("#"+_vm._s(item.form_no))]),_c('p',{staticClass:"mb-0 font-weight-300"},[_vm._v(_vm._s(_vm.formatDate(item.form_date)))])])]}},{key:"list-total_farmer",fn:function({ item }){return [_c('p',{staticClass:"mb-0 text-no-wrap"},[_vm._v(" "+_vm._s([undefined, null].includes(item.total_farmer) ? "-" : item.total_farmer)+" peserta ")])]}},{key:"list-target_area_id",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column min-w-100px"},[_c('p',{staticClass:"mb-0 text-09-em"},[_vm._v(_vm._s(item.target_areas_name))]),_c('p',{staticClass:"mb-0 text-grey text-09-em"},[_vm._v(" MU "+_vm._s(item.managementunits_name)+" ")])])]}},{key:"list-indicator",fn:function({ item }){return [_c('div',{staticClass:"indicator-wrapper pt-1"},[_c('div',{staticClass:"indicator",class:{
          success: item.is_verified == 1,
          danger: item.is_verified == 0,
        }})])]}},{key:"detail-action",fn:function({ item }){return [_c('div',[(
          item.is_verified == 0 &&
          _vm.$store.state.User &&
          _vm.$_sys.isAllowed('sosialisasi-program-verification-create')
        )?_c('v-btn',{attrs:{"variant":"success"},on:{"click":_vm.onVerify}},[_vm._v("Verifikasi")]):_vm._e(),(
          item.is_verified == 1 &&
          _vm.$store.state.User &&
          _vm.$_sys.isAllowed('sosialisasi-program-unverification-create')
        )?_c('v-btn',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.onVerify(false)}}},[_vm._v("Unverifikasi")]):_vm._e()],1)]}},{key:"list-bottom-action",fn:function({ item }){return [(item.is_verified)?_c('v-btn',{staticClass:"mt-1",attrs:{"small":"","variant":"danger"},on:{"click":function($event){return _vm.onExport(item)}}},[(!_vm.exportIds.includes(item.id))?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-pdf-box")]):_c('v-progress-circular',{attrs:{"color":"danger","size":15,"width":2,"indeterminate":""}}),_c('span',{staticClass:"text-09-em ml-1"},[_vm._v("Export")])],1):_vm._e()]}},{key:"create-form",fn:function(){return [_c('program-soc-form')]},proxy:true},{key:"detail-slave-raw",fn:function({ data }){return [_c('program-soc-detail',{attrs:{"data":data}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }